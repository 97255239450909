import { useState, useEffect } from "react";
import { connect } from "react-redux";

import ALink from "~/components/features/custom-link";
import { modalActions } from "~/store/modal";
import { scrollWithOffset } from "~/utils/helper";

function StickyFooter(props) {
  const { section } = props;
  const onButtonClick = () => {
    scrollWithOffset("product-details", 10, (ele) => {
      ele.click();
    });
  };

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <>
          {section === "shilajit" ? (
            <div id="FloatButton" class="floatingcta-ps">
              <a href="#ORDERNOW" class="floatbuttoncta-shila w-button">
                Supercharge Now
              </a>
            </div>
          ) : (
            <div className="floatingcta-ps">
              <ALink
                href="#"
                onClick={onButtonClick}
                className="floatbuttoncta w-button"
              >
                Buy Now
              </ALink>
            </div>
          )}
        </>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    cartList: state.cart.data || [],
    user: state.user.data,
  };
}

export default connect(mapStateToProps, {
  setCartVisibility: modalActions.setCartVisibility,
})(StickyFooter);
