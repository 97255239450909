import React, { useEffect, useState } from "react";

const TimerComponent = () => {
  const [minutes, setMinutes] = useState(30);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        clearInterval(timerInterval);
        alert("Discount may still work. Checkout today!");
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [minutes, seconds]);

  if (minutes === 0 && seconds === 0) {
    return <></>;
  }

  return (
    <div id="timer-ending" className="timerr">
      Exclusive: Limited-Time ⏰ Offer:{" "}
      <span style={{ fontWeight: "bold", color: "red" }}>{`${minutes}:${
        seconds < 10 ? "0" : ""
      }${seconds}`}</span>
    </div>
  );
};

export default TimerComponent;
